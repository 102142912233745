<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-3 mb-3">
        <span class="text-muted">Section > </span><span>Documents</span>
      </div>
    </div>

    <section id="iq-favorites">
      <div class="container-fluid">
        <div
          class="row"
          v-for="subsection in subsections"
          v-bind:key="subsection.id"
        >
          <div class="col-sm-12 overflow-hidden">
            <div class="iq-main-header d-flex align-items-center">
              <h4 class="main-title mt-4">
                {{ capitalizeWords(subsection) }}
              </h4>
            </div>
            <div class="text-center">
              <ul class="list-group">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center"
                  v-for="doc in getDocumentsBySubsection(subsection)"
                  :key="doc.id"
                >
                  {{ doc.Title }}
                  <a :href="doc.Document" target="_blank">
             
                  <i class="fa fa-download text-danger"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { db } from "@/db.js";
export default {
  data() {
    return {
      documents: null,
      section: null,
      subsections: null,
    };
  },
  mounted() {
    this.getDocuments();
    this.getSection();
  },
  methods: {
    getDocuments(subsection) {
      const _this = this;
      this.$bind(
        "documents",
        db
          .collection("posts")
          .where("Section", "==", "docs")
          
      ).then(() => {});
    },
    getDocumentsBySubsection(subsection) {
     let docs = this.documents.filter((doc) => doc.Subsection == subsection);
        return _.orderBy(docs, item => item.Title, ['asc']);

      
    },

    getSection() {
      const _this = this;
      this.$bind(
        "section",
        db.collection("sections").where("section", "==", "docs")
      ).then(() => {
        _this.subsections = _this.section[0].subsections;
      });
    },
    capitalizeWords(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>

<style>

.list-group-item {
  background-color: #2c2c2c;
  color: #ffffff;
}
</style>